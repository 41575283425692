import React from 'react';
import { Card, CardMedia, CardContent, CardActions, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Example: Your styled components (from your snippet)
const TitleStyle = styled('h2')`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled('p')`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

// Hover overlay
const HoverOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#3f3e3e',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity 0.3s ease, visibility 0.3s ease',
}));

// Styled Card
const HoverCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  maxWidth: 500,
  overflow: 'hidden',
  backgroundColor: '#f7f7f7',
  borderRadius: '16px',
  '&:hover .hoverOverlay': {
    opacity: 1,
    visibility: 'visible',
  },
}));

/**
 * MediaCard Props:
 * @param {string} image           - Card image URL
 * @param {string} title           - Title text
 * @param {string} explanation     - Main description
 * @param {string} moreExplanation - Additional info shown on hover
 */
export default function MediaCard({
  image = '/static/images/cards/contemplative-reptile.jpg',
  title = 'Lizard',
  explanation = 'Lizards are a widespread group...',
  moreExplanation = 'More details about the lizard...',
}) {
  return (
    <HoverCard>
      <CardMedia sx={{ width: 500, height: 300 }} image={image} title={title} />
      
      <CardContent>
        <TitleStyle>{title}</TitleStyle>
        <SubtitleStyle>{explanation}</SubtitleStyle>
      </CardContent>
      
      {/* <CardActions>
        <Button size="small" variant="outlined">
          Share
        </Button>
        <Button size="small" variant="contained">
          Learn More
        </Button>
      </CardActions> */}
      
      <HoverOverlay className="hoverOverlay">
        <TitleStyle>Additional Info</TitleStyle>
        <SubtitleStyle>{moreExplanation}</SubtitleStyle>
      </HoverOverlay>
    </HoverCard>
  );
}
