import React, { useState, useEffect , useCallback } from "react";

// Helper function to truncate long text
const truncateText = (text, maxLength = 30) => {
  if (!text) return "N/A";
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

// Modal Component to display full content
const Modal = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "black",
          padding: "20px",
          borderRadius: "8px",
          maxWidth: "600px",
          maxHeight: "80vh",
          overflowY: "auto",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2>Full Content</h2>
        <p>{content}</p>
        <button
          onClick={onClose}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            cursor: "pointer",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Component to render a readable table
const ReadableTable = ({ data, isRequestTable = false }) => {
  const [modalContent, setModalContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
  return (
    <div style={{ overflowX: "auto", marginTop: "20px" }}>
      <table
        border="1"
        style={{
          width: "100%",
          borderCollapse: "collapse",
          tableLayout: "fixed",
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Upload ID</th>
            <th style={{ width: "15%" }}>Name</th>
            <th style={{ width: "20%" }}>Email</th>
            {!isRequestTable && <th style={{ width: "20%" }}>Message</th>}
            {isRequestTable && <th style={{ width: "20%" }}>Project Description</th>}
            {isRequestTable && <th style={{ width: "10%" }}>Budget</th>}
            {isRequestTable && <th style={{ width: "10%" }}>Phone</th>}
            {isRequestTable && <th style={{ width: "15%" }}>Files</th>}
            <th style={{ width: "10%" }}>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item) => (
              <tr key={item.uploadId}>
                <td>{truncateText(item.uploadId, 15)}</td>
                <td>{truncateText(item.name, 20)}</td>
                <td>
                  {truncateText(item.email, 25)}{" "}
                  {item.email && item.email.length > 25 && (
                    <button
                      onClick={() => openModal(item.email)}
                      style={{
                        marginLeft: "5px",
                        padding: "2px 5px",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                    >
                      View
                    </button>
                  )}
                </td>
                {!isRequestTable && (
                  <td>
                    {truncateText(item.message, 30)}{" "}
                    {item.message && item.message.length > 30 && (
                      <button
                        onClick={() => openModal(item.message)}
                        style={{
                          marginLeft: "5px",
                          padding: "2px 5px",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                      >
                        View
                      </button>
                    )}
                  </td>
                )}
                {isRequestTable && (
                  <>
                    <td>
                      {truncateText(item.projectDescription, 30)}{" "}
                      {item.projectDescription &&
                        item.projectDescription.length > 30 && (
                          <button
                            onClick={() => openModal(item.projectDescription)}
                            style={{
                              marginLeft: "5px",
                              padding: "2px 5px",
                              fontSize: "12px",
                              cursor: "pointer",
                            }}
                          >
                            View
                          </button>
                        )}
                    </td>
                    <td>{item.budget || "N/A"}</td>
                    <td>{item.phone || "N/A"}</td>
                    <td>
                      {item.files && item.files.length > 0 ? (
                        item.files.map((file, index) => (
                          <div key={index}>
                            <a
                              href={file.signedUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                                display: "block",
                                margin: "5px 0",
                                wordWrap: "break-word",
                              }}
                            >
                              {file.filename}
                            </a>
                          </div>
                        ))
                      ) : (
                        "No Files"
                      )}
                    </td>
                  </>
                )}
                <td>{new Date(item.timestamp).toLocaleString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={isRequestTable ? 8 : 5}>No data found.</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for displaying full content */}
      <Modal isOpen={isModalOpen} onClose={closeModal} content={modalContent} />
    </div>
  );
};


const AdminPage = () => {
  const [activePage, setActivePage] = useState("contactUs");
  const [contactMessages, setContactMessages] = useState([]);
  const [requestMessages, setRequestMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [sortOrder, setSortOrder] = useState("newest");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const contactMessagesApiUrl = "https://lb0fyad163.execute-api.us-east-1.amazonaws.com/api/contact_us_items";
  const requestMessagesApiUrl = "https://lb0fyad163.execute-api.us-east-1.amazonaws.com/api/requests";
  const toggleSortOrder = () => {
    setSortOrder((prev) => (prev === "newest" ? "oldest" : "newest"));
  };
  const fetchContactMessages = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch(contactMessagesApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setContactMessages(data);
        setError("");
      } else {
        throw new Error("Invalid credentials or failed to fetch Contact Us messages.");
      }
    } catch (err) {
      setError("Failed to fetch Contact Us messages. Please re-enter your credentials.");
      setIsLoggedIn(false);
    } finally {
      setIsLoading(false);
    }
  }, [username, password]);

  const fetchRequestMessages = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch(requestMessagesApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(`${username}:${password}`)}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRequestMessages(data);
        setError("");
      } else {
        throw new Error("Invalid credentials or failed to fetch request messages.");
      }
    } catch (err) {
      setError("Failed to fetch request messages. Please re-enter your credentials.");
      setIsLoggedIn(false);
    } finally {
      setIsLoading(false);
    }
  }, [username, password]);

  useEffect(() => {
    if (isLoggedIn) {
      if (activePage === "contactUs") {
        fetchContactMessages();
      } else {
        fetchRequestMessages();
      }
    }
  }, [activePage, isLoggedIn, fetchContactMessages, fetchRequestMessages]);


  const handleLogin = () => {
    if (username && password) {
      // Simulate login success
      setIsLoggedIn(true);
      setError(""); // Clear any previous errors
    } else {
      setError("Please enter both username and password.");
    }
  };

  const getSortedData = (data) => {
    return [...data].sort((a, b) => {
      const dateA = new Date(a.timestamp); // Replace 'date' with the actual field name for date
      const dateB = new Date(b.timestamp);
      return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Admin Page</h1>

      {!isLoggedIn ? (
        <div style={{ maxWidth: "300px", margin: "0 auto" }}>
          <h2>Enter Credentials</h2>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{ width: "100%", marginBottom: "10px", padding: "8px" }}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: "100%", marginBottom: "10px", padding: "8px" }}
          />
          <button
            onClick={handleLogin}
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#4CAF50",
              color: "white",
              cursor: "pointer",
            }}
          >
            Login
          </button>
          {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}
        </div>
      ) : (
        <>
          <div style={{ marginBottom: "20px" }}>
            <button
              onClick={() => setActivePage("contactUs")}
              style={{
                marginRight: "10px",
                padding: "10px 20px",
                cursor: "pointer",
                backgroundColor: activePage === "contactUs" ? "#4CAF50" : "#f0f0f0",
                color: activePage === "contactUs" ? "white" : "black",
              }}
            >
              Contact Us Messages
            </button>
            <button
              onClick={() => setActivePage("requests")}
              style={{
                padding: "10px 20px",
                cursor: "pointer",
                backgroundColor: activePage === "requests" ? "#4CAF50" : "#f0f0f0",
                color: activePage === "requests" ? "white" : "black",
              }}
            >
              Requests
            </button>
          </div>

          <div style={{ marginBottom: "10px" }}>
            <button onClick={toggleSortOrder} style={{ padding: "10px 20px", cursor: "pointer" }}>
              Sort by: {sortOrder === "newest" ? "Newest to Oldest" : "Oldest to Newest"}
            </button>
          </div>

          {isLoading ? (
            <p>Loading...</p>
          ) : error ? (
            <p style={{ color: "red" }}>{error}</p>
          ) : activePage === "contactUs" ? (
            <ReadableTable data={getSortedData(contactMessages)} isRequestTable={false} />
          ) : (
            <ReadableTable data={getSortedData(requestMessages)} isRequestTable={true} />
          )}
        </>
      )}
    </div>
  );
};

export default AdminPage;
