import React, { useState } from "react";
import styled from "styled-components";
import PhoneInput from 'react-phone-number-input';
import "react-phone-number-input/style.css";

export default function GetStarted() {
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    projectDescription: "",
    budget: ""
  });
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });

  // Configuration
  const API_URL = process.env.REACT_APP_API_URL || 'https://lb0fyad163.execute-api.us-east-1.amazonaws.com/api/';
  const FALLBACK_EMAIL = 'support@octopuda.com'; // Fallback contact email

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // Clear any previous errors when user starts typing
    setSubmitError("");
    setSubmitStatus({ type: '', message: '' });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileError("");

    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        setFileError("File size must be less than 5MB");
        e.target.value = '';
        setFile(null);
        return;
      }

      const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'image/jpeg',
        'image/png'
      ];

      if (!allowedTypes.includes(selectedFile.type)) {
        setFileError("Please upload a PDF, DOC, DOCX, TXT, JPG, or PNG file");
        e.target.value = '';
        setFile(null);
        return;
      }

      setFile(selectedFile);
    }
  };

  const handleChangePhone = (value) => {
    setPhone(value);
  };

  // Function to handle failed submissions
  const handleFailedSubmission = async (formDataToSave) => {
    // Save form data to localStorage as backup
    try {
      const timestamp = new Date().toISOString();
      localStorage.setItem('formBackup_' + timestamp, JSON.stringify({
        formData: formDataToSave,
        timestamp
      }));

      // Create a fallback message for the user
      return `We're experiencing technical difficulties. Please try again later or contact us directly at ${FALLBACK_EMAIL}. Your form data has been saved locally.`;
    } catch (error) {
      return `We're experiencing technical difficulties. Please try again later or contact us directly at ${FALLBACK_EMAIL}.`;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setSubmitError("");
    setSubmitStatus({ type: 'info', message: 'Submitting your request...' });

    const submissionData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      submissionData.append(key, value);
    });
    submissionData.append("phone", phone);
    if (file) {
      submissionData.append("file", file);
    }

    try {
      // First attempt - regular submission
      const response = await fetch(`${API_URL}upload`, {
        method: "POST",
        body: submissionData,
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
        }
      });
      console.log(response)
      // If first attempt fails, try fallback submission
      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}`);
      }

      const result = await response.json();
      setSubmitStatus({
        type: 'success',
        message: 'Thank you! Your project request has been submitted successfully.'
      });

      // Reset form on success
      setFormData({
        name: "",
        email: "",
        projectDescription: "",
        budget: ""
      });
      setPhone("");
      setFile(null);
      
    } catch (error) {
      console.error("Submission error:", error);
      
      // Handle the error and get appropriate message
      const fallbackMessage = await handleFailedSubmission(formData);
      
      setSubmitStatus({
        type: 'error',
        message: fallbackMessage
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    // This will take the user back one step in browser history
    window.history.back();
  };

  return (
    <Container className="darkBg">

      <FormSection onSubmit={handleSubmit}>

      <CloseButton onClick={handleClose} aria-label="Close">&times;</CloseButton>

        <SectionTitle>Tell Us About Your Project</SectionTitle>

        {submitStatus.message && (
          <StatusMessage type={submitStatus.type}>
            {submitStatus.message}
          </StatusMessage>
        )}

        <Label htmlFor="nameInput">Name *</Label>
        <Input
          type="text"
          id="nameInput"
          name="name"
          placeholder="Enter your name"
          value={formData.name}
          onChange={handleChange}
          required
          disabled={submitting}
        />

        <Label htmlFor="emailInput">Email *</Label>
        <Input
          type="email"
          id="emailInput"
          name="email"
          placeholder="Enter your email"
          value={formData.email}
          onChange={handleChange}
          required
          disabled={submitting}
        />

        <Label htmlFor="phoneInput">Phone Number *</Label>
        <PhoneWrapper>
          <PhoneInput
            international
            defaultCountry="US"
            value={phone}
            onChange={handleChangePhone}
            placeholder="Enter phone number"
            required
            disabled={submitting}
          />
        </PhoneWrapper>

        <Label htmlFor="projectDescription">Project Description *</Label>
        <Textarea
          id="projectDescription"
          name="projectDescription"
          placeholder="Tell us more about your project goals, timelines, and requirements"
          value={formData.projectDescription}
          onChange={handleChange}
          required
          disabled={submitting}
        />

        <Label htmlFor="files">
          Upload Files (Optional - Max 5MB - PDF, DOC, DOCX, TXT, JPG, or PNG)
        </Label>
        <FileInput
          type="file"
          id="files"
          name="files"
          onChange={handleFileChange}
          accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
          disabled={submitting}
        />
        {fileError && <ErrorMessage>{fileError}</ErrorMessage>}
        {file && <FileName>Selected file: {file.name}</FileName>}

        <Label htmlFor="budget">Estimated Budget (Optional)</Label>
        <Input
          type="text"
          id="budget"
          name="budget"
          placeholder="E.g., $10,000"
          value={formData.budget}
          onChange={handleChange}
          disabled={submitting}
        />

        <Button type="submit" disabled={submitting}>
          {submitting ? "Submitting..." : "Submit"}
        </Button> 

        <FormNote>
          * Required fields
        </FormNote>
      </FormSection>
    </Container>
  );
}

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: red;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Container = styled.div`
  padding: 20px;
`;

const FormSection = styled.form`
  background-color: white;
  position: relative;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  
  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

const Textarea = styled.textarea`
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: vertical;
  min-height: 120px;
  width : 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
  &:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
`;

const FileInput = styled.input`
  width: 100%;
  margin-bottom: 1rem;
  
  &:disabled {
    cursor: not-allowed;
  }
`;


const Button = styled.button`
  flex: 2;
  width: 100%;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: ${props => props.isLoading 
    ? '#cccccc'
    : 'linear-gradient(90deg, #019301, #49CB86)'};
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover:not(:disabled) {
    transform: translateY(-3px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    background: linear-gradient(90deg, #49CB86, #019301);
  }

  &:active:not(:disabled) {
    transform: translateY(1px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.7;
  }
`;

const StatusMessage = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  text-align: center;
  
  ${props => {
    switch(props.type) {
      case 'error':
        return 'background-color: #ffebee; color: #c62828;';
      case 'success':
        return 'background-color: #e8f5e9; color: #2e7d32;';
      default:
        return 'background-color: #e3f2fd; color: #1565c0;';
    }
  }}
`;

const ErrorMessage = styled.div`
  color: #c62828;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const FileName = styled.div`
  color: #2e7d32;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const PhoneWrapper = styled.div`
  margin-bottom: 1rem;
  
  .PhoneInputInput {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    
    &:disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }
`;

const FormNote = styled.p`
  text-align: center;
  color: #666;
  font-size: 0.875rem;
  margin-top: 1rem;
`;