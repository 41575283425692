import React from "react";
import styled from "styled-components";
// import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";
// Assets
import AddImage1 from "../../assets/img/Asset2.svg";

import machine from "../../assets/img/machine.png"
import software from "../../assets/img/software.jpg"

// components 
import ClientStats from "../Elements/ClientStats";
import MediaCard from "../Elements/ServiceCard";
import FullButton from "../Buttons/FullButton";

export default function Services() {
  const navigate = useNavigate(); // Hook for navigation

  const goTogetstarted = () => {
    navigate("/getstarted"); // Navigate to the new page
  };

  // const scrollToContact = () => {
  //   scroller.scrollTo("contact", {
  //     duration: 800,
  //     delay: 0,
  //     smooth: "easeInOutQuart",
  //   });
  // };

  return (
    <Wrapper id="services">
      <div className="greyBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientStats />
        </div>
      </div>

      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container" style={{ textAlign: "center" }}>
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
            <p className="font13">
              Experience excellence with our top-notch services—crafted to bring your vision to life 
              with precision, innovation, and dedication.
            </p>
          </HeaderInfo>
          
          {/* Center the two cards */}
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "50px",    // space between the cards
            marginTop: "40px"
          }}>
            {/* First Card */}
            <MediaCard
              image={machine}
              title="Machine Learning and AI"
              explanation="Developing predictive models and intelligent algorithms to drive insights and decision-making."
              moreExplanation="Machine Learning and AI leverage statistical models and algorithms 
              to enable computers to learn from data and make predictions or decisions. By identifying 
              patterns and adapting based on experience, these technologies drive innovations like speech recognition, 
              autonomous vehicles, and personalized recommendations."
            />
            {/* Second Card */}
            <MediaCard
              image={software}
              title="Software and Web Dev"
              explanation="Creating engaging and responsive websites that provide an optimal user experience."
              moreExplanation="Software Development encompasses the entire process of 
              designing, coding, testing, and maintaining applications to solve real-world problems. 
              Web Development is a specialized area of software development focused on building dynamic 
              and responsive websites or web applications accessible through the internet."
            />
          </div>
        </div>

        <div className="greyBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">We are a</h4>
                <h2 className="font40 extraBold">Study of Creativity</h2>
                <p className="font12">
                Octopuda is about exploring creative solutions through innovative design and technology. We are dedicated to unlocking new possibilities for our clients, blending cutting-edge advancements with imaginative artistry.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div style={{ width: "190px" }}>
                    <FullButton title="Get Started" action={goTogetstarted} />
                  </div>
                  {/* <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Contact Us" action={scrollToContact} border />
                  </div> */}
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 108%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
  }
`;